import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ISubcategoryState } from 'src/@types/product';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: ISubcategoryState = {
  isLoading: false,
  error: null,
  subcategories: [],
  subcategory: null,
};

const slice = createSlice({
  name: 'subcategory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getSubcategoriesSuccess(state, action) {
      state.isLoading = false;
      state.subcategories = action.payload;
    },

    // GET PRODUCT
    getSubcategorySuccess(state, action) {
      state.isLoading = false;
      state.subcategory = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSubcategories() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/categories//subcategories', {
        params: { perPage: 100000 }
      });
      dispatch(slice.actions.getSubcategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSubcategory(name: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/categories//subcategories/${name}`);
      dispatch(slice.actions.getSubcategorySuccess(response.data.product));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
