import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'src/utils/axios';
import toFormData from 'src/utils/toFormData';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Button, Typography } from '@mui/material';
import ConfirmDialog from 'src/components/confirm-dialog';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { IBlogNewPost } from '../../../@types/blog';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFEditor,
  RHFUpload,
  RHFTextField,
  RHFAutocomplete,
} from '../../../components/hook-form';


// ----------------------------------------------------------------------

type Props = {
  isEdit?: boolean;
  current?: IBlogNewPost;
};

export type FormValuesProps = IBlogNewPost & {
  tagsOptions: { value: number, label: string }[]
};

export default function NewsNewEditForm({ isEdit, current }: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [openConfirm, setOpenConfirm] = useState(false)

  const NewBlogSchema = Yup.object().shape({
    title: Yup.string().required('Укажите название'),
    text: Yup.string().required('Не заполнен текст новости'),
  });

  const defaultValues = useMemo(
    () => ({
      title: current?.title || '',
      text: current?.text || '',
      isVisible: current?.isVisible || false,
      isPopular: current?.isPopular || false,
      image: current?.image || '',
      imageAlt: current?.imageAlt || '',
      metaTitle: current?.metaTitle || '',
      metaDesc: current?.metaDesc || '',

    }), [current]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axios({
        method: isEdit ? 'patch' : 'post',
        url: isEdit && current
          ? `/news/${current.id}`
          : `/news`,
        data: toFormData({ ...data })
      })

      reset();
      enqueueSnackbar('Post success!');
      navigate(PATH_DASHBOARD.blog.posts);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFile = () => {
    setValue('image', null);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const handleDelete = async () => {
    if (!current) return

    await axios({
      method: 'delete',
      url: `/news/${current.id}`
    })
    enqueueSnackbar('Новость удалена');
    navigate(PATH_DASHBOARD.blog.posts);
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="title" label="Название новости" />

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Текст новости
                </Typography>

                <RHFEditor simple name="text" />
              </Stack>

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Обложка
                </Typography>


                <RHFTextField name="imageAlt" label="Описание изображения" multiline />

                <RHFUpload
                  name="image"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  onDelete={handleRemoveFile}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <div>
                <RHFSwitch
                  name="isVisible"
                  label="Отображать на сайте"
                  labelPlacement="start"
                  sx={{ mb: 1, mx: 0, width: 1, justifyContent: 'space-between' }}
                />

                <RHFSwitch
                  name="isPopular"
                  label="Популярное"
                  labelPlacement="start"
                  sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                />
              </div>

              <RHFTextField name="metaTitle" label="Meta title" />

              <RHFTextField
                name="metaDesc"
                label="Meta description"
                fullWidth
                multiline
              />

            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={isSubmitting}
            >
              {isEdit ? 'Сохранить' : 'Создать'}
            </LoadingButton>

            {isEdit &&
              <LoadingButton
                fullWidth
                type="button"
                variant="contained"
                size="large"
                color='error'
                onClick={() => setOpenConfirm(true)}
              >
                Удалить
              </LoadingButton>}

            <ConfirmDialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              title="Удалить новость"
              content="Вы уверены что хотите удалить новость?"
              action={
                <Button variant="contained" color="error" onClick={handleDelete}>
                  Удалить
                </Button>
              }
            />
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
