import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import axios from 'src/utils/axios';
import { useEffect, useState } from 'react';
import { IBlogNewPost, IReview } from 'src/@types/blog';
// @mui
import { Container } from '@mui/material';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
// sections
import ReviewNewEditForm from 'src/sections/@dashboard/blog/ReviewNewEditForm';

// ----------------------------------------------------------------------

export default function ReviewEditPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams()

  const [current, setCurrent] = useState<IReview>()

  useEffect(() => {
    axios.get(`/reviews/${id}`)
      .then(res => setCurrent(res.data))
  }, [id])

  if (!current) return <LoadingScreen />

  return (
    <>
      <Helmet>
        <title> Редактирование отзыва </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Редактирование отзыва"
          links={[
            {
              name: 'Главная',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Отзывы',
              href: PATH_DASHBOARD.reviews.root,
            },
            {
              name: current?.id.toString(),
            },
          ]}
        />

        <ReviewNewEditForm isEdit current={current} />
      </Container>
    </>
  );
}
