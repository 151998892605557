// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: '',
  //   items: [
  //     // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'Статистика', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //     // { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      // USER
      // {
      //   title: 'Клиенты',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'Список', path: PATH_DASHBOARD.user.list },
      //     // { title: 'create', path: PATH_DASHBOARD.user.new },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // INVOICE
      {
        title: 'Заявки',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.invoice.list },
        //   { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
        //   { title: 'create', path: PATH_DASHBOARD.invoice.new },
        //   { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
        // ],
      },
      {
        title: 'Портфолио',
        path: PATH_DASHBOARD.portfolio.root,
        icon: ICONS.label,
      },
      // E-COMMERCE
      {
        title: 'Товары',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          // { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
          { title: 'Категории', path: PATH_DASHBOARD.eCommerce.catList },
          { title: 'Товары', path: PATH_DASHBOARD.eCommerce.list },
          // { title: 'Создать', path: PATH_DASHBOARD.eCommerce.new },
          // { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
          // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
        ],
      },

      // SERVICES
      {
        title: 'Услуги',
        path: PATH_DASHBOARD.services.root,
        icon: ICONS.booking,
        // children: [
        //   { title: 'Новости', path: PATH_DASHBOARD.blog.posts },
        //   { title: 'create', path: PATH_DASHBOARD.blog.new },
        // ],
      },

      // BLOG
      {
        title: 'Новости',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.calendar,
        // children: [
        //   { title: 'Новости', path: PATH_DASHBOARD.blog.posts },
        //   { title: 'create', path: PATH_DASHBOARD.blog.new },
        // ],
      },

      // REVIEWS
      {
        title: 'Отзывы',
        path: PATH_DASHBOARD.reviews.root,
        icon: ICONS.chat,
        // children: [
        //   { title: 'Новости', path: PATH_DASHBOARD.blog.posts },
        //   { title: 'create', path: PATH_DASHBOARD.blog.new },
        // ],
      },
      {
        title: 'Контакты',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.booking,
      },

    ],
  },

];

export default navConfig;
