// ----------------------------------------------------------------------

import { IProduct } from "./product";
import { IUserAccountAddress, IUserAccountGeneral } from "./user";

export type IInvoiceAddress = {
  id: string;
  name: string;
  address: string;
  company: string;
  email: string;
  phone: string;
};

export type IInvoiceItem = {
  id: string;
  title: string;
  description: string;
  quantity: number;
  price: number;
  total: number;
  service: string;
};

export type IInvoice = {
  id: number
  name: string
  phone: string
  status: Status
  total: number | null
  place: string | null
  area: string | null
  type: string | null
  createdAt: Date
  updatedAt: Date | null

  products: {
    Product: Product;
    productQty: number
  }[]
};

export type Product = {
  id: number
  categoryId: number
  name: string
  image: string
  images: string[]
  vendorCode: string | null
  price: number | null
  description: string | null
  priceInSet: number | null
  bestDeals: boolean | null
  homePage: boolean | null
  hit: boolean | null
  createdAt: Date
  updatedAt: Date | null
}


export const PaymentMethod = {
  CASH: 'CASH',
  CARD: 'CARD'
};

// eslint-disable-next-line
export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod]

export const ShippingMethod = {
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY',
  CDEK: 'CDEK',
  POST: 'POST'
};

// eslint-disable-next-line
export type ShippingMethod = (typeof ShippingMethod)[keyof typeof ShippingMethod]


export const Status = {
  NEW: 'NEW',
  CONFIRMED: 'CONFIRMED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED'
};

// eslint-disable-next-line
export type Status = (typeof Status)[keyof typeof Status]