import React from 'react';
import {Helmet} from "react-helmet-async";
import {Container} from "@mui/material";
import {useSettingsContext} from "../../../components/settings";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {PortfolioNewEditForm} from "../../../sections/@dashboard/portfolio/index";

const PortfolioCreatePage = () => {
    const { themeStretch } = useSettingsContext();

    return (
        <>
            <Helmet>
                <title> Создание портфолио</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                <CustomBreadcrumbs
                    heading="Создание портфолио"
                    links={[
                        { name: 'Главная', href: PATH_DASHBOARD.root },
                        {
                            name: 'Портфолио',
                            href: PATH_DASHBOARD.portfolio.root,
                        },
                        { name: 'Создать' },
                    ]}
                />
                <PortfolioNewEditForm />
            </Container>
        </>
    );
};

export default PortfolioCreatePage;