import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { HOST_API_KEY } from 'src/config-global';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Avatar, Typography, CardContent, Stack, Link, CardActions, Button } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
// @types
import { IService } from '../../../@types/blog';
// components
import Image from '../../../components/image';
import Iconify from '../../../components/iconify';
import TextMaxLine from '../../../components/text-max-line';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

// ----------------------------------------------------------------------

type Props = {
    post: IService;
    index?: number;
};

export default function ServiceCard({ post, index }: Props) {
    const isDesktop = useResponsive('up', 'md');

    const { id, name, price, description, priceInSet } = post;

    return (
        <Card>
            <CardContent sx={{ aspectRatio: '4/3' }} >

                <TextMaxLine
                    variant={isDesktop ? 'h5' : 'subtitle2'}
                    line={2}
                    persistent
                >
                    {name}
                </TextMaxLine>
                <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                    sx={{
                        opacity: 0.64,
                        color: 'common.white',
                    }}
                >
                    Цена {price?.toLocaleString('ru')} ₽
                </Typography>

            </CardContent>

            <CardActions>
                <Button size="small" component={RouterLink} to={PATH_DASHBOARD.services.edit(id.toString())}>Изменить</Button>
            </CardActions>
        </Card>
    );

}
