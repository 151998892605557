import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'src/utils/axios';
import toFormData from 'src/utils/toFormData';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Button, Typography } from '@mui/material';
import ConfirmDialog from 'src/components/confirm-dialog';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { IBlogNewPost, IReview } from '../../../@types/blog';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFEditor,
  RHFUpload,
  RHFTextField,
  RHFAutocomplete,
} from '../../../components/hook-form';


// ----------------------------------------------------------------------

type Props = {
  isEdit?: boolean;
  current?: IReview;
};

export type FormValuesProps = IReview

export default function ReviewNewEditForm({ isEdit, current }: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [openConfirm, setOpenConfirm] = useState(false)

  const NewBlogSchema = Yup.object().shape({
    rate: Yup.number().required('Укажите рейтинг'),
    review: Yup.string().required('Не заполнен отзыв'),
  });

  const defaultValues = useMemo(
    () => ({
      rate: current?.rate || 0,
      review: current?.review || '',

    }), [current]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axios({
        method: isEdit ? 'patch' : 'post',
        url: isEdit && current
          ? `/reviews/${current.id}`
          : `/reviews`,
        data: toFormData({ ...data })
      })

      reset();
      enqueueSnackbar('Успешно!');
      navigate(PATH_DASHBOARD.reviews.list);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const handleDelete = async () => {
    if (!current) return

    await axios({
      method: 'delete',
      url: `/reviews/${current.id}`
    })
    enqueueSnackbar('Отзыв удален');
    navigate(PATH_DASHBOARD.reviews.list);
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Отзыв
                </Typography>

                <RHFTextField name="review" multiline />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>

              <RHFTextField
                name="rate"
                label="Рейтинг"
                type='number'
                onChange={e => setValue('rate', +e.target.value)}
              />

            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={isSubmitting}
            >
              {isEdit ? 'Сохранить' : 'Создать'}
            </LoadingButton>

            {isEdit &&
              <LoadingButton
                fullWidth
                type="button"
                variant="contained"
                size="large"
                color='error'
                onClick={() => setOpenConfirm(true)}
              >
                Удалить
              </LoadingButton>}

            <ConfirmDialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              title="Удалить новость"
              content="Вы уверены что хотите удалить новость?"
              action={
                <Button variant="contained" color="error" onClick={handleDelete}>
                  Удалить
                </Button>
              }
            />
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
