import { useState } from 'react';
// @mui
import {
  Link,
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
  TextField,
  Modal,
  Card,
} from '@mui/material';
// @types
import { IInvoice, Status } from '../../../../@types/invoice';
// components
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import ConfirmDialog from '../../../../components/confirm-dialog';
import Box from '@mui/system/Box';

const STATUS = [
  { key: 'NEW', value: 'Новая' },
  { key: 'CONFIRMED', value: 'Подтверждена' },
  { key: 'COMPLETED', value: 'Завершена' },
  { key: 'CANCELED', value: 'Отменена' }
]

// ----------------------------------------------------------------------

type Props = {
  row: IInvoice;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onStatusChange: (arg: string) => void
};

export default function InvoiceTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onStatusChange,
  onDeleteRow,
}: Props) {

  const {
    id,
    name,
    phone,
    status,
    total,
    place,
    area,
    type,
    createdAt,
    updatedAt,
    products
  } = row;

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false)
  const [openProducts, setOpenProducts] = useState(false)
  const [newStatus, setNewStatus] = useState<string>()

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleStatusChange = async () => {
    newStatus && onStatusChange(newStatus)
    setOpenChangeStatus(false)
    setNewStatus(undefined)
  }

  return (
    <>
      <TableRow hover selected={selected}>

        <TableCell align="left">
          <Label
            variant="soft"
            color={
              (status === 'COMPLETED' && 'success') ||
              (status === 'CONFIRMED' && 'warning') ||
              (status === 'CANCELED' && 'error') ||
              'default'
            }
          >
            {status}
          </Label>
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>

            <div>
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>

              <Link
                noWrap
                variant="body2"
                // onClick={onViewRow}
                sx={{ color: 'text.disabled', cursor: 'pointer' }}
              >
                {`Заявка #${id}`}
              </Link>
            </div>
          </Stack>
        </TableCell>

        <TableCell align="center">{phone}</TableCell>

        <TableCell align="center">{new Date(createdAt).toLocaleString('ru')}</TableCell>

        <TableCell align="center">
          {products.length > 0 &&
            <Link
              noWrap
              onClick={() => setOpenProducts(true)}
              sx={{ cursor: 'pointer' }}
            >
              {products.length}
            </Link>}
        </TableCell>

        <TableCell align="center" >{total && total.toLocaleString('ru') + ' ₽'}</TableCell>

        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
          {place} {area} {type}
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >

        <MenuItem
          onClick={() => {
            setOpenChangeStatus(true)
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Статус
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Удалить
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Удалить заявку?"
        content="Заявка будет удалена без возможности восстановления"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Удалить
          </Button>
        }
      />

      <ConfirmDialog
        open={openChangeStatus}
        onClose={() => setOpenChangeStatus(false)}
        title="Выберите новый статус"
        content={

          <TextField
            fullWidth
            select
            label="Статус"
            defaultValue={STATUS.find(s => s.key === status)?.key}
            onChange={e => setNewStatus(e.target.value)}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: { maxHeight: 220 },
                },
              },
            }}
            sx={{
              m: 2,
              maxWidth: { md: 160 },
              textTransform: 'capitalize',
            }}
          >
            {STATUS.map((option) => (
              <MenuItem
                key={option.key}
                value={option.key}
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option.value}
              </MenuItem>
            ))}
          </TextField>

        }
        action={
          <Button variant="contained" color="primary" onClick={handleStatusChange}>
            Сохранить
          </Button>
        }
      />

      <Modal
        open={openProducts}
        onClose={() => setOpenProducts(false)}
      >
        <Card sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Список товаров в заявке
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ol>
              {products.map(product =>
                <li key={`product-${product.Product.id}`}>
                  {product.Product.name}&emsp;-&emsp;{product.productQty}&nbsp;шт&emsp;-&emsp;{product.Product.price?.toLocaleString('ru')}&nbsp;₽
                </li>
              )}
            </ol>
          </Typography>
        </Card>
      </Modal>


    </>
  );
}
