import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ICategoryState } from 'src/@types/product';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: ICategoryState = {
  isLoading: false,
  error: null,
  categories: [],
  category: null,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET PRODUCT
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/categories', {
        params: { perPage: 100000 }
      });
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategory(name: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/categories/${name}`);
      dispatch(slice.actions.getCategorySuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
