import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import axios from 'src/utils/axios';
import { useEffect, useState } from 'react';
import { IBlogNewPost } from 'src/@types/blog';
// @mui
import { Container } from '@mui/material';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
// sections
import { NewsNewEditForm } from '../../sections/@dashboard/blog';

// ----------------------------------------------------------------------

export default function NewsNewPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams()

  const [current, setCurrent] = useState<IBlogNewPost>()

  useEffect(() => {
    axios.get(`/news/${id}`)
      .then(res => setCurrent(res.data))
  }, [id])

  if (!current) return <LoadingScreen />

  return (
    <>
      <Helmet>
        <title> Редактирование новости </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Редактирование новости"
          links={[
            {
              name: 'Главная',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Новости',
              href: PATH_DASHBOARD.blog.root,
            },
            {
              name: current?.title,
            },
          ]}
        />

        <NewsNewEditForm isEdit current={current} />
      </Container>
    </>
  );
}
