import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import axios from 'src/utils/axios';
import { useEffect, useState } from 'react';
import { IBlogNewPost } from 'src/@types/blog';
// @types
import { IBanner } from 'src/@types/banner';
// @mui
import { Container } from '@mui/material';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
// sections
import BannerNewEditForm from 'src/sections/@dashboard/blog/BannerNewEditForm';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function BannerEditPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams()

  const [current, setCurrent] = useState<IBanner>()

  useEffect(() => {
    axios.get(`/banners/${id}`)
      .then(res => setCurrent(res.data))
  }, [id])

  if (!current) return <LoadingScreen />

  return (
    <>
      <Helmet>
        <title> Изменить баннер </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Редактирование баннера"
          links={[
            {
              name: 'Главная',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Баннеры',
              href: PATH_DASHBOARD.blog.root,
            },
            {
              name: current?.title ?? '',
            },
          ]}
        />

        <BannerNewEditForm isEdit current={current} />
      </Container>
    </>
  );
}
