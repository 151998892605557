import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import axios from 'src/utils/axios';
import { useEffect, useState } from 'react';
import { IBlogNewPost } from 'src/@types/blog';
// @types
import { IPromotion } from 'src/@types/promotions';
// @mui
import { Container } from '@mui/material';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
// sections
import { PromoNewEditForm } from 'src/sections/@dashboard/promotions';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function PromoEditPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams()

  const [current, setCurrent] = useState<IPromotion>()

  useEffect(() => {
    axios.get(`/promotions/${id}`)
      .then(res => setCurrent(res.data))
  }, [id])

  if (!current) return <LoadingScreen />

  return (
    <>
      <Helmet>
        <title> Редактирование акции </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Редактирование акции"
          links={[
            {
              name: 'Главная',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Акции',
              href: PATH_DASHBOARD.blog.root,
            },
            {
              name: current?.title ?? '',
            },
          ]}
        />

        <PromoNewEditForm isEdit current={current} />
      </Container>
    </>
  );
}
