import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import axios from 'src/utils/axios';
import { useEffect, useState } from 'react';
import { IInvoice } from 'src/@types/invoice';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock_
import { _invoices } from '../../_mock/arrays';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
// sections
import InvoiceDetails from '../../sections/@dashboard/invoice/details';

// ----------------------------------------------------------------------

export default function InvoiceDetailsPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();

  const [currentInvoice, setCurrentInvoice] = useState<IInvoice>()

  useEffect(() => {
    axios.get(`users//orders/${id}`)
      .then(res => setCurrentInvoice(res.data))
  }, [id])

  return (
    <>
      <Helmet>
        <title> Заказ </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Детали заказа"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            {
              name: 'Заказы',
              href: PATH_DASHBOARD.invoice.root,
            },
            { name: `INV-${currentInvoice?.id}` },
          ]}
        />

        <InvoiceDetails invoice={currentInvoice} />
      </Container>
    </>
  );
}
