import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {SelectChangeEvent} from "@mui/material/Select";
import {Helmet} from "react-helmet-async";
import {Button, Card, Container, IconButton, Table, TableBody, TableContainer, Tooltip} from "@mui/material";
import {
    emptyRows,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TablePaginationCustom,
    TableSelectedAction,
    TableSkeleton,
    useTable
} from "../../../components/table";
import {useSettingsContext} from "../../../components/settings";
import {IProduct} from "../../../@types/product";
import {PATH_DASHBOARD} from "../../../routes/paths";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import Iconify from "../../../components/iconify";
import {ProductTableToolbar} from "../../../sections/@dashboard/e-commerce/product-list";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import ConfirmDialog from "../../../components/confirm-dialog";
import axios from "../../../utils/axios";
import {PortFolioTableRow} from "../../../sections/@dashboard/portfolio/index";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
    { id: 'id', label: 'ID', align: 'left' },
    { id: 'title', label: 'Название', align: 'left' },
    { id: 'subtitle', label: 'Краткое описание', align: 'left' },
    { id: 'description', label: 'Описание', align: 'left' },
    { id: '' },
];

const STATUS_OPTIONS = [
    { value: 'in_stock', label: 'In stock' },
    { value: 'low_stock', label: 'Low stock'},
    { value: 'out_of_stock', label: 'Out of stock' },
];

// ----------------------------------------------------------------------
const PortfolioListPage = () => {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({
        defaultOrderBy: 'createdAt',
        defaultOrder: 'desc',
        defaultRowsPerPage: 10,
        defaultDense: true,
    });

    const { themeStretch } = useSettingsContext();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)

    const [tableData, setTableData] = useState<IProduct[]>([]);

    const [filterName, setFilterName] = useState('');

    const [filterStatus, setFilterStatus] = useState<string[]>([]);

    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        axios.get('portfolio')
            .then((res:any) => {
                setTableData(res?.data)
            })
            .finally(()=>{setIsLoading(false)})
    }, [])


    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(order, orderBy),
        filterName,
        filterStatus,
    });

    const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const denseHeight = dense ? 60 : 80;

    const isFiltered = filterName !== '' || !!filterStatus.length;

    const isNotFound = (!dataFiltered.length && !!filterName) || (!isLoading && !dataFiltered.length);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleFilterStatus = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        setPage(0);
        setFilterStatus(typeof value === 'string' ? value.split(',') : value);
    };

    const handleDeleteRow = (id: string) => {
        axios.delete(`portfolio/${id}`)
            .then(() => {
                const deleteRow = tableData.filter((row) => row.id.toString() !== id.toString());
                setSelected([]);
                setTableData(deleteRow);
                if (page > 0) {
                    if (dataInPage.length < 2) {
                        setPage(page - 1);
                    }
                }
            })
    };

    const handleDeleteRows = (selectedRows: string[]) => {
        const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id.toString()));
        setSelected([]);
        setTableData(deleteRows);

        if (page > 0) {
            if (selectedRows.length === dataInPage.length) {
                setPage(page - 1);
            } else if (selectedRows.length === dataFiltered.length) {
                setPage(0);
            } else if (selectedRows.length > dataInPage.length) {
                const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1;
                setPage(newPage);
            }
        }
    };

    const handleEditRow = (id: string) => {
        navigate(PATH_DASHBOARD.portfolio.edit(id));
    };

    const handleViewRow = (id: string) => {
        navigate(PATH_DASHBOARD.portfolio.edit(id));
    };

    const handleResetFilter = () => {
        setFilterName('');
        setFilterStatus([]);
    };

    return (
        <>
            <Helmet>
                <title> Список портфолио </title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                <CustomBreadcrumbs
                    heading="Список портфолио"
                    links={[
                        { name: 'Главная', href: PATH_DASHBOARD.root },
                        {
                            name: 'Портфолио',
                            href: PATH_DASHBOARD.portfolio.root,
                        },
                        { name: 'Список' },
                    ]}
                    action={
                        <Button
                            component={RouterLink}
                            to={PATH_DASHBOARD.portfolio.new}
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                            Создать
                        </Button>
                    }
                />

                <Card>
                    <ProductTableToolbar
                        filterName={filterName}
                        filterStatus={filterStatus}
                        onFilterName={handleFilterName}
                        onFilterStatus={handleFilterStatus}
                        statusOptions={STATUS_OPTIONS}
                        isFiltered={isFiltered}
                        onResetFilter={handleResetFilter}
                    />

                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                        <TableSelectedAction
                            dense={dense}
                            numSelected={selected.length}
                            rowCount={tableData.length}
                            onSelectAllRows={(checked) =>
                                onSelectAllRows(
                                    checked,
                                    tableData.map((row) => row.id.toString())
                                )
                            }
                            action={
                                <Tooltip title="Delete">
                                    <IconButton color="primary" onClick={handleOpenConfirm}>
                                        <Iconify icon="eva:trash-2-outline" />
                                    </IconButton>
                                </Tooltip>
                            }
                        />

                        <Scrollbar>
                            <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id.toString())
                                        )
                                    }
                                />

                                <TableBody>
                                    {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) =>
                                            row ? (
                                                <PortFolioTableRow
                                                    key={row.id}
                                                    row={row}
                                                    selected={selected.includes(row.id)}
                                                    onSelectRow={() => onSelectRow(row.id)}
                                                    onDeleteRow={() => handleDeleteRow(row.id)}
                                                    onEditRow={() => handleEditRow(row.id)}
                                                    onViewRow={() => handleViewRow(row.id)}
                                                />
                                            ) : (
                                                !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                                            )
                                        )}

                                    <TableEmptyRows
                                        height={denseHeight}
                                        emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                                    />

                                    <TableNoData isNotFound={isNotFound} />
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        count={dataFiltered.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        //
                        dense={dense}
                        onChangeDense={onChangeDense}
                    />
                </Card>
            </Container>

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content={
                    <>
                        Вы уверены, что хотите удалить? <strong> {selected.length} </strong> items?
                    </>
                }
                action={
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleDeleteRows(selected);
                            handleCloseConfirm();
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    );
}

// ----------------------------------------------------------------------

function applyFilter({
                         inputData,
                         comparator,
                         filterName,
                         filterStatus,
                     }: {
    inputData: IProduct[];
    comparator: (a: any, b: any) => number;
    filterName: string;
    filterStatus: string[];
}) {
    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (filterName) {
        inputData = inputData.filter(
            (product) => product.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
        );
    }

    // if (filterStatus.length) {
    //   inputData = inputData.filter((product) => filterStatus.includes(product.inventoryType));
    // }

    return inputData;
};

export default PortfolioListPage;