import { Helmet } from "react-helmet-async";

import { Container } from "@mui/material";
// sections
// redux
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import SettingsEditForm from "../../../sections/@dashboard/e-commerce/SettingsEditForm";

// ----------------------------------------------------------------------

export default function SettingsEditPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Редактирование настроек</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Редактирование настроек"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            {
              name: 'Настройки',
              href: PATH_DASHBOARD.settings.root,
            },
          ]}
        />
        <SettingsEditForm />
      </Container>
    </>
  );
}
