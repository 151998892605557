import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  single?: boolean
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, single, ...other }, ref) => {
    const theme = useTheme()

    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src="/logo/logo-full.png"
        sx={{ height: 40, width: 91, cursor: 'pointer', ...sx }}
      />
    )

    const logoSingle = (
      <Box
        component="img"
        src="/logo/logo-single.png"
        sx={{ height: 40, width: 40, cursor: 'pointer', ...sx }}
      />
    )

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {single ? logoSingle : logo}
      </Link>
    );
  }
);

export default Logo;
