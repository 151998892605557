import * as Yup from 'yup';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'src/utils/axios';
// redux
import { useDispatch } from 'src/redux/store';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import LoadingScreen from 'src/components/loading-screen';
import { Box, Card, Grid, Stack, Typography, InputAdornment, Button } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
// components
import { CustomFile } from '../../../components/upload';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFUpload,
  RHFTextField,
  RHFAutocomplete,
} from '../../../components/hook-form';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/iconify';
import {ICharacteristic, IPortfolio} from "../../../@types/portfolio";
import toFormData from "../../../utils/toFormData";

// ----------------------------------------------------------------------

interface FormValuesProps {
  title: string;
  id:number;
  subtitle: string;
  images: (CustomFile | string)[];
  image: CustomFile | string;
  description: string;
  characteristics: ICharacteristic[];
}

type Props = {
  isEdit?: boolean;
  product?:IPortfolio | undefined
};

export default function ProductNewEditForm({ isEdit, product }: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const NewProductSchema = Yup.object().shape({
    title: Yup.string().required('Укажите название'),
    // subtitle: Yup.string().required('Укажите краткое описание'),
    image: Yup.mixed().required('Загрузите изображение'),
    // images: Yup.mixed().required('Загрузите изображения'),
    // description: Yup.string().required('Описание портфолио обязательно'),
  });

  const defaultValues = useMemo(
    () => ({
      title: product?.title || '',
      subtitle: product?.subtitle || '',
      description: product?.description || '',
      image: product?.image || '',
      images: product?.images || [],
      characteristics: product?.characteristics || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && product) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, product]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const characteristics = data.characteristics.map((item)=>{
        return {name: item.name, value: item.value}
      })
      console.log(data);
      const formData = toFormData({ ...data, characteristics });
      await axios({
        method: isEdit ? 'put' : 'post',
        url: isEdit && product ? `/portfolio/${product.id}` : `/portfolio`,
        data: formData,
      });

      enqueueSnackbar(!isEdit ? 'Товар успешно создан!' : 'Товар успешно обновлен!');
      reset();
      navigate(PATH_DASHBOARD.portfolio.root);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropSingle = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = values.images && values.images?.filter((file) => file !== inputFile);
    setValue('images', filtered);
  };

  const handleRemoveAllFiles = () => {
    setValue('images', []);
  };

  const handleDeleteFile = () => {
    setValue('image', '');
  };
  console.log(product);
  if (isEdit && !product) return <LoadingScreen />;
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <RHFTextField name="title" label="Название портфолио" />
                <RHFTextField name="subtitle" label="Краткое описание портфолио" multiline />
                <RHFTextField name="description" label="Полное описание портфолио" multiline />
              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Главное изображение
                  </Typography>
                  <RHFUpload
                    name="image"
                    maxSize={3145728}
                    onDrop={handleDropSingle}
                    onDelete={handleDeleteFile}
                    onUpload={() => console.log('ON UPLOAD')}
                  />
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack spacing={3}>

            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                  Характеристики
                </Typography>

                {values.characteristics?.map((variant, index) => (
                  <Stack spacing={2} key={`variant-${index}`}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Параметры портфолио
                    </Typography>
                    <RHFTextField
                      name=""
                      label="Параметр"
                      value={values.characteristics[index].name}
                      onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        const variantsWithUpdatedText = [...values.characteristics];
                        variantsWithUpdatedText[index].name = e.target.value;
                        setValue('characteristics', variantsWithUpdatedText);
                      }}
                    />
                    <RHFTextField
                      name=""
                      label="Значение"
                      value={values.characteristics[index].value}
                      onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        const variantsWithUpdatedPrice = [...values.characteristics];
                        variantsWithUpdatedPrice[index].value = e.target.value;
                        setValue('characteristics', variantsWithUpdatedPrice);
                      }}
                    />

                    <Button
                      variant="text"
                      startIcon={<Iconify icon="eva:minus-fill" />}
                      onClick={() => {
                        const newVariants = [...values.characteristics];
                        newVariants.splice(index, 1);
                        setValue('characteristics', newVariants);
                      }}
                    >
                      Удалить
                    </Button>
                  </Stack>
                ))}
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => {
                    const newVariants = [...values.characteristics];
                    newVariants.push({
                      name: '',
                      value: '',
                    });
                    setValue('characteristics', newVariants);
                  }}
                >
                  Создать
                </Button>
              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack spacing={1}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Дополнительные изображения
                  </Typography>

                  <RHFUpload
                    multiple
                    thumbnail
                    name="images"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => console.log('ON UPLOAD')}
                  />
                </Stack>
              </Stack>
            </Card>

            {/* <Card sx={{ p: 3 }}>
                            <Stack spacing={3}>
                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Meta
                                </Typography>

                                <RHFTextField name="metaTitle" label="Meta title" multiline />
                                <RHFTextField name="metaDesc" label="Meta description" multiline />
                            </Stack>
                        </Card> */}

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!isEdit ? 'Создать' : 'Сохранить'}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
