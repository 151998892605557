import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'src/utils/axios';
import toFormData from 'src/utils/toFormData';
// redux
import { getSubcategories } from 'src/redux/slices/subcategory';
import { useDispatch, useSelector } from 'src/redux/store';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import LoadingScreen from 'src/components/loading-screen';
import { Box, Card, Grid, Stack, Typography, InputAdornment, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Button, Checkbox, SelectChangeEvent, TextField, Autocomplete } from '@mui/material';
import Iconify from 'src/components/iconify';
import { sentenceCase } from 'change-case';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { ICategory, IProduct, ISubcategory } from '../../../@types/product';
// components
import { CustomFile } from '../../../components/upload';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFSelect,
  RHFEditor,
  RHFUpload,
  RHFTextField,
  RHFRadioGroup,
  RHFAutocomplete,
} from '../../../components/hook-form';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<IProduct, 'images' | 'image'> {
  image: CustomFile | string
  images: (CustomFile | string)[]
  categoryOption?: { value: number, label: string, catParams: ICategory['productParams'] } | null
  serviceOptions: { value: number, label: string }[]
  paramsOptions: { value: number, label: string, productParamId: number }[]
  productOptions: { value: number, label: string }[]
}

type Props = {
  isEdit?: boolean;
  currentProduct?: IProduct;
};

export default function ProductNewEditForm({ isEdit, currentProduct }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [product, setProduct] = useState<IProduct>()

  const [categoriesOptions, setCategoriesOptions] = useState<{ value: number, label: string, catParams: ICategory['productParams'] }[]>([])
  const [catOpen, setCatOpen] = useState(false)
  const catLoading = catOpen && categoriesOptions.length === 0

  const [serviceOptions, setServiceOptions] = useState<{ value: number, label: string }[]>([])
  const [serviceOpen, setServiceOpen] = useState(false)
  const serviceLoading = serviceOpen && serviceOptions.length === 0

  const [productOptions, setProductOptions] = useState<{ value: number, label: string }[]>([])
  const [productOpen, setProductOpen] = useState(false)
  const productLoading = productOpen && productOptions.length === 0

  const { enqueueSnackbar } = useSnackbar()

  const NewProductSchema = Yup.object().shape({
    // title: Yup.string().required('Укажите название'),
    // image: Yup.mixed().required('Загрузите изображение'),
    // images: Yup.array().min(1, 'Выберите более 2х дополнительных изображений'),
    // subcategories: Yup.array().min(1, 'Выберите минимум одну подкатегорию'),
    // price: Yup.number().moreThan(0, 'Цена не может быть равна 0.00 ₽'),
    // description: Yup.string().required('Описание товара обязательно'),
  });

  const defaultValues = useMemo(
    () => ({
      name: product?.name ?? '',
      description: product?.description ?? '',
      image: product?.image ?? '',
      images: product?.images ?? [],
      vendorCode: product?.vendorCode ?? '',
      price: product?.price ?? 0,

      bestDeals: product?.bestDeals ?? false,
      homePage: product?.homePage ?? false,
      hit: product?.hit ?? false,

      priceInSet: product?.priceInSet ?? 0,

      categoryOption: product ? { value: product?.category.id, label: product?.category.name, catParams: product?.category.productParams } : null,
      serviceOptions: product?.set ? product.set.map(el => ({ value: el.service.id, label: el.service.name })) : [],
      productOptions: product?.similarProducts ? product.similarProducts.map(el => ({ value: el.similarProduct.id, label: el.similarProduct.name })) : [],

      paramsOptions: product ? product?.category.productParams.map(productParam => {
        const found = product?.parameters.find(p => p.ProductParamOption?.productParamId === productParam.id)
        return found
          ? {
            value: found.ProductParamOption.id,
            label: found.ProductParamOption.name,
            productParamId: found.ProductParamOption?.productParamId
          }
          : { productParamId: 0 }
      }) : []

    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product, categoriesOptions]
  )

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch()

  useEffect(() => {
    !isEdit && values.categoryOption &&
      setValue('paramsOptions', [...Array(values.categoryOption.catParams.length).fill({ productParamId: 0 })])
  }, [values.categoryOption])

  useEffect(() => {
    if (currentProduct) axios.get(`/products/${currentProduct.id}`)
      .then(res => setProduct(res.data))
      .catch(e => console.log(e))
  }, [currentProduct])

  // Load category options
  useEffect(() => {
    let active = true
    if (!catLoading) return

    axios.get('/categories')
      .then(res => {
        active && setCategoriesOptions(res.data.map(
          (cat: ICategory) => ({ value: cat.id, label: cat.name, catParams: cat.productParams })
        ))
      })
      .catch(e => console.log(e))

    return () => { active = false }
  }, [catLoading])


  // Load service options
  useEffect(() => {
    let active = true
    if (!serviceLoading) return

    axios.get('/services')
      .then(res => active && setServiceOptions(res.data.map(
        (service: { id: number, name: string }) => ({ value: service.id, label: service.name })
      ))).catch(e => console.log(e))

    return () => { active = false }
  }, [serviceLoading])

  // Load product options
  useEffect(() => {
    let active = true
    if (!productLoading) return

    axios.get('/products')
      .then(res => active && setProductOptions(res.data.map(
        (product: IProduct) => ({ value: product.id, label: product.name })
      ))).catch(e => console.log(e))

    return () => { active = false }
  }, [productLoading])

  useEffect(() => {
    if (isEdit && currentProduct) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, product]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // eslint-disable-next-line
      const { categoryOption, serviceOptions, paramsOptions, productOptions, ...rest } = data

      const parameters = paramsOptions
        ? paramsOptions.filter(p =>{
          return p?.productParamId!=undefined &&  p?.productParamId !== 0
        } ).map(option => ({ productParamOptionId: option?.value }))
        : []
      const services = serviceOptions ? serviceOptions.map(option => ({ serviceId: option?.value })) : []
      const similar = productOptions ? productOptions.map(option => ({ similarProductId: option?.value })) : []

      await axios({
        method: isEdit ? 'patch' : 'post',
        url: isEdit && currentProduct
          ? `/products/${currentProduct.id}`
          : `/products`,
        data: toFormData({ ...rest, parameters, services, similar, categoryId: categoryOption?.value })
      })

      enqueueSnackbar(!isEdit ? 'Товар успешно создан!' : 'Товар успешно обновлен!');
      reset()
      navigate(PATH_DASHBOARD.eCommerce.list)
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropSingle = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = values.images && values.images?.filter((file) => file !== inputFile);
    setValue('images', filtered);
  };

  const handleRemoveAllFiles = () => {
    setValue('images', []);
  };

  const handleDeleteFile = () => {
    setValue('image', '');
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>

          <Stack spacing={3}>

            <Card sx={{ p: 3 }}>

              <Stack spacing={3}>

                <RHFTextField name="name" label="Название товара" />

                <RHFTextField name="description" label="Описание товара" multiline />

                <RHFAutocomplete
                  name="categoryOption"
                  label="Категория"
                  open={catOpen}
                  onOpen={() => setCatOpen(true)}
                  onClose={() => setCatOpen(false)}
                  value={values.categoryOption}
                  options={categoriesOptions}
                  loading={catLoading}
                  getOptionLabel={(option) => option && typeof option === 'object' && option.label ? `${option.label}` : ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  ChipProps={{ size: 'small' }}
                />

              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Главное изображение
                  </Typography>

                  <RHFUpload
                    thumbnail
                    name="image"
                    maxSize={3145728}
                    onDrop={handleDropSingle}
                    onDelete={handleDeleteFile}
                    onUpload={() => console.log('ON UPLOAD')}
                  />
                </Stack>

              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack spacing={1}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Дополнительные изображения
                  </Typography>

                  <RHFUpload
                    multiple
                    thumbnail
                    name="images"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                    onUpload={() => console.log('ON UPLOAD')}
                  />
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>

                <RHFTextField name="vendorCode" label="Артикул производителя" />

                <Stack spacing={1}>
                  <RHFSwitch name="bestDeals" label="Лучшее предложение" />
                  <RHFSwitch name="homePage" label="Отображать на главной странице" />
                  <RHFSwitch name="hit" label="Хит" />
                </Stack>

              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <RHFTextField
                  name="price"
                  label="Цена"
                  placeholder="0.00"
                  onChange={(event) =>
                    setValue('price', Number(event.target.value), { shouldValidate: true })
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          ₽
                        </Box>
                      </InputAdornment>
                    ),
                    type: 'number',
                  }}
                />

              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>

              <Stack spacing={3}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Сопутствующие товары
                </Typography>

                <RHFAutocomplete
                  name="productOptions"
                  label="Товары"
                  open={productOpen}
                  onOpen={() => setProductOpen(true)}
                  onClose={() => setProductOpen(false)}
                  multiple
                  value={values.productOptions}
                  options={productOptions}
                  loading={productLoading}
                  getOptionLabel={(option) => option && typeof option === 'object' && option.label ? `${option.label}` : ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  ChipProps={{ size: 'small' }}
                />

              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>

              <Stack spacing={3}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Сопутствующие услуги
                </Typography>

                <RHFAutocomplete
                  name="serviceOptions"
                  label="Услуги"
                  open={serviceOpen}
                  onOpen={() => setServiceOpen(true)}
                  onClose={() => setServiceOpen(false)}
                  multiple
                  value={values.serviceOptions}
                  options={serviceOptions}
                  loading={serviceLoading}
                  getOptionLabel={(option) => option && typeof option === 'object' && option.label ? `${option.label}` : ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  ChipProps={{ size: 'small' }}
                />

                <Stack spacing={3} mb={2}>
                  <RHFTextField
                    name="priceInSet"
                    label="Цена товара при покупке с услугой"
                    placeholder="0.00"
                    onChange={(event) =>
                      setValue('priceInSet', Number(event.target.value), { shouldValidate: true })
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box component="span" sx={{ color: 'text.disabled' }}>
                            ₽
                          </Box>
                        </InputAdornment>
                      ),
                      type: 'number',
                    }}
                  />

                </Stack>
              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>

              <Stack spacing={3}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Характеристики
                </Typography>

                {values.categoryOption?.catParams && values.categoryOption.catParams.map((param, index) =>
                  <RHFAutocomplete
                    key={`category-param-${param.id}`}
                    name=''
                    label={param.name}
                    value={values.paramsOptions.filter(opt => opt?.productParamId === param.id)[0] ?? null}
                    onChange={(e, value) => {
                      const updatedParams = values.paramsOptions.map((v, i) => {
                        if (v instanceof Array) return v[0]
                        if (i === index) return value
                        return v
                      })
                      setValue('paramsOptions', updatedParams)
                    }}
                    options={param.options.map(option => ({ value: option.id, label: option.name, productParamId: option?.productParamId }))}
                    getOptionLabel={(option) => option && typeof option === 'object' ? `${option.label}` : ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    ChipProps={{ size: 'small' }}
                  />)}

              </Stack>
            </Card>

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!isEdit ? 'Создать' : 'Сохранить изменения'}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
