import axios from 'axios'
// config
import { HOST_API_KEY } from '../config-global'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY, withCredentials:true });

(async function addFp() {
  const fp = await FingerprintJS.load()
  const { visitorId } = await fp.get()
  localStorage.setItem('fingerprint', visitorId)
})()

axiosInstance.defaults.headers.common['fingerprint'] = localStorage.getItem('fingerprint')

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
      originalRequest._isRetry = true
      try {
        const response = await axios.get(`${HOST_API_KEY}auth/refresh`, {headers:{'fingerprint':localStorage.getItem('fingerprint')}})
        localStorage.setItem('token', response?.data?.body?.token)
      } catch (e) {
        console.log('No auth')
      }
    }
    return Promise.reject(error)
  }

  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
