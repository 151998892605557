import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "src/utils/axios";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Card, Grid, Stack } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// @types
// components
import { useSnackbar } from "../../../components/snackbar";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { ISettings } from "../../../@types/settings";
import LoadingScreen from "../../../components/loading-screen";

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<ISettings, 'id'> {}

export default function SettingsEditForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [settings, setSettings] = useState<ISettings>();

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    axios
      .get(`/settings/1`)
      .then((res) => setSettings(res.data))
      .catch((e) => console.log(e))
      .finally(()=>{setLoading(false)})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settingsSchema = Yup.object().shape({
    legalStatus: Yup.string().required('Legal status is required'),
    address: Yup.string().required('Address is required'),
    email: Yup.string().required('email is required'),
    phone: Yup.string().required('Phone is required'),
  });

  // need to do smth with this cringe
  const defaultValues = useMemo(
    () => ({
      legalStatus: settings?.legalStatus || '',
      address: settings?.address || '',
      email: settings?.email || '',
      phone: settings?.phone || '',

    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(settingsSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const newSettings = await axios({
        method: 'patch',
        url: '/settings/1',
        data:{...data, latitude:'0', longitude:'1'},
      });
			setSettings(newSettings.data)

      reset();
      enqueueSnackbar('Настройки успешно обновлены!');
      navigate(PATH_DASHBOARD.settings.root);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <LoadingScreen />;
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <RHFTextField name="legalStatus" label="Юридический статус" />
                <RHFTextField name="address" label="Адрес" />
                <RHFTextField name="email" label="Email" />
                <RHFTextField name="phone" label="Номер для связи" />
              </Stack>
            </Card>
            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              Сохранить
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
