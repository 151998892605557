// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// _mock_
import { IInvoice, Status } from '../../../../@types/invoice';
// components
import Label from '../../../../components/label';
import Image from '../../../../components/image';
import Scrollbar from '../../../../components/scrollbar';
//
import InvoiceToolbar from './InvoiceToolbar';

// ----------------------------------------------------------------------

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  invoice?: IInvoice;
};

export default function InvoiceDetails({ invoice }: Props) {

  const { themeMode } = useSettingsContext()

  if (!invoice) {
    return null;
  }

  const {
    id,
    createdAt,
    status,
    shippingMethod,
    paymentMethod,
    comment,
    sum,
    discount,
    bonusUsed,
    bonusAccrued,
    shipping,
    total,
    userId,
    addressId,
    updatedAt,
    updatedBy,
    items,
    user,
    address,
  } = invoice;

  return (
    <>
      <InvoiceToolbar invoice={invoice} />

      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Image disabledEffect alt="logo" src={themeMode === 'light' ? '/logo/header_b.png' : '/logo/header_w.png'} sx={{ maxWidth: 240 }} />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant="soft"
                color={
                  (status === Status.COMPLETED && 'success') ||
                  (status === Status.ONHAND && 'warning') ||
                  (status === Status.CANCELED && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {status}
              </Label>

              <Typography variant="h6">{`Заказ ${id}`}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Исполнитель
            </Typography>

            <Typography variant="body2">Подарки</Typography>

            <Typography variant="body2">Республика Татарстан, Казань Рашида Вагапова 3</Typography>

            <Typography variant="body2">+7 999 345-67-89</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Заказчик
            </Typography>

            <Typography variant="body2">{user.firstName} {user.lastName}</Typography>

            <Typography variant="body2">{`${address.city}, ${address.streetName}, ${address.buildingNumber}, кв.${address.apartment}`}</Typography>

            <Typography variant="body2">{user.phone}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Дата создания
            </Typography>

            <Typography variant="body2">{fDate(createdAt)}</Typography>
          </Grid>
        </Grid>

        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar>
            <Table sx={{ minWidth: 960 }}>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>

                  <TableCell align="left">Товары</TableCell>

                  <TableCell align="center">Кол-во</TableCell>

                  <TableCell align="center">Цена</TableCell>

                  <TableCell align="right">Сумма</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {items.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>

                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">{row.product.title}</Typography>

                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          {row.product.description}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell align="center">{row.productQty}</TableCell>

                    <TableCell align="center">{fCurrency(row.productPrice)}</TableCell>

                    <TableCell align="right">{fCurrency(row.productQty * row.productPrice)}</TableCell>
                  </TableRow>
                ))}

                <StyledRowResult>
                  <TableCell colSpan={3} />

                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    <Box sx={{ mt: 2 }} />
                    Подитог
                  </TableCell>

                  <TableCell align="right" width={120} sx={{ typography: 'body1' }}>
                    <Box sx={{ mt: 2 }} />
                    {sum.toLocaleString('ru')}&nbsp;₽
                  </TableCell>
                </StyledRowResult>

                <StyledRowResult>
                  <TableCell colSpan={3} />

                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    Скидка
                  </TableCell>

                  <TableCell
                    align="right"
                    width={120}
                    sx={{ typography: 'body1' }}
                  >
                    {discount !== 0 && fCurrency(-discount)}
                  </TableCell>
                </StyledRowResult>

                <StyledRowResult>
                  <TableCell colSpan={3} />

                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    Использовано бонусов
                  </TableCell>

                  <TableCell align="right" width={120} sx={{ typography: 'body1' }}>
                    {bonusUsed !== 0 && -bonusUsed.toLocaleString('ru')}
                  </TableCell>
                </StyledRowResult>

                <StyledRowResult>
                  <TableCell colSpan={3} />

                  <TableCell align="right" sx={{ typography: 'h6' }}>
                    Итого
                  </TableCell>

                  <TableCell align="right" width={140} sx={{ typography: 'h6' }}>
                    {total.toLocaleString('ru')}&nbsp;₽
                  </TableCell>
                </StyledRowResult>
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <Divider sx={{ mt: 5 }} />

        <Grid container>

          <Grid item xs={12} md={12} sx={{ py: 3, textAlign: 'right' }}>
            <Typography variant="subtitle2">Возникли вопросы?</Typography>

            <Typography variant="body2">eshop-gift@mail.ru</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
