import React, { useState } from "react";
import { Button, IconButton, Link, MenuItem, Stack, TableCell, TableRow } from "@mui/material";
import { HOST_API_STATIC } from "../../../config-global";
import Image from "../../../components/image";
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
import ConfirmDialog from "../../../components/confirm-dialog";
import { IPortfolio } from "../../../@types/portfolio";

type Props = {
    row: IPortfolio;
    selected: boolean;
    onEditRow: VoidFunction;
    onViewRow: VoidFunction;
    onSelectRow: VoidFunction;
    onDeleteRow: VoidFunction;
};

const PortFolioTableRow = ({
                               row,
                               selected,
                               onSelectRow,
                               onDeleteRow,
                               onEditRow,
                               onViewRow,
                           }: Props)=> {
    const { title, image, id, description, subtitle } = row;

    const [openConfirm, setOpenConfirm] = useState(false);

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const imgPath = `${HOST_API_STATIC}/${image}`;

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell align="left">{id}</TableCell>

                <TableCell>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Image
                            disabledEffect
                            visibleByDefault
                            alt={title}
                            src={imgPath}
                            sx={{ borderRadius: 1.5, width: 48, height: 48 }}
                        />

                        <Link noWrap color="inherit" variant="subtitle2" onClick={onViewRow} sx={{ cursor: 'pointer' }}>
                            {title}
                        </Link>
                    </Stack>
                </TableCell>
                <TableCell align="left">{subtitle?.slice(0,30)}...</TableCell>
                <TableCell align="left">{description?.slice(0,30)}...</TableCell>

                <TableCell align="right">
                    <IconButton color={openPopover ? 'primary' : 'default'} onClick={handleOpenPopover}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top" sx={{ width: 140 }}>
                <MenuItem
                    onClick={() => {
                        handleOpenConfirm();
                        handleClosePopover();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="eva:trash-2-outline" />
                    Удалить
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onEditRow();
                        handleClosePopover();
                    }}
                >
                    <Iconify icon="eva:edit-fill" />
                    Изменить
                </MenuItem>
            </MenuPopover>

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Удалить"
                content="Вы уверены, что хотите удалить?"
                action={
                    <Button variant="contained" color="error" onClick={onDeleteRow}>
                        Удалить
                    </Button>
                }
            />
        </>
    );
}
export default PortFolioTableRow;
