import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'src/utils/axios';
import toFormData from 'src/utils/toFormData';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CustomFile } from 'src/components/upload';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Button, Typography, TextField } from '@mui/material';
// @types
import { IPromotion } from 'src/@types/promotions';
// components
import ConfirmDialog from 'src/components/confirm-dialog';
import { DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFEditor,
  RHFUpload,
  RHFTextField,
  RHFAutocomplete,
} from '../../../components/hook-form';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';


// ----------------------------------------------------------------------

type Props = {
  isEdit?: boolean;
  current?: IPromotion;
};

export type FormValuesProps = Omit<IPromotion, 'image'> & {
  image: CustomFile | string | null;
}

export default function PromoNewEditForm({ isEdit, current }: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [openConfirm, setOpenConfirm] = useState(false)

  const NewBlogSchema = Yup.object().shape({
    image: Yup.mixed().required('Выберите изображение'),
  });

  const defaultValues = useMemo(
    () => ({
      title: current?.title ?? '',
      description: current?.description ?? '',
      start: current?.start ?? null,
      end: current?.end ?? null,
      image: current?.image ?? '',
      imageAlt: current?.imageAlt ?? '',
      metaTitle: current?.metaTitle ?? '',
      metaDesc: current?.metaDesc ?? '',
    }), [current]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {

      await axios({
        method: isEdit ? 'patch' : 'post',
        url: isEdit && current
          ? `/promotions/${current.id}`
          : `/promotions`,
        data: toFormData(data)
      })
      reset();
      enqueueSnackbar(isEdit ? 'Акция успешно изменена' : 'Акция успешно добалена');
      navigate(PATH_DASHBOARD.promotions.list);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFile = () => {
    setValue('image', null);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const handleDelete = async () => {
    if (!current) return

    await axios({
      method: 'delete',
      url: `/promotions/${current.id}`
    })
    enqueueSnackbar('Акция удалена');
    navigate(PATH_DASHBOARD.promotions.list);
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>

              <RHFTextField name="title" label="Название акции" />

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Описание акции
                </Typography>

                <RHFEditor simple name="description" />
              </Stack>

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Обложка
                </Typography>


                <RHFTextField name="imageAlt" label="Описание изображения" multiline />

                <RHFUpload
                  name="image"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  onDelete={handleRemoveFile}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>

                <DatePicker
                  label="Дата начала"
                  value={values.start}
                  onChange={date => setValue('start', date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                    />
                  )}
                />

                <DatePicker
                  label="Дата окончания"
                  value={values.end}
                  onChange={date => setValue('end', date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                    />
                  )}
                />

              </Stack>
            </Card>


            <Card sx={{ p: 3 }}>

              <Stack spacing={3}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Meta
                </Typography>

                <RHFTextField name="metaTitle" label="Meta title" multiline />
                <RHFTextField name="metaDesc" label="Meta description" multiline />
              </Stack>
            </Card>

            <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>

              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                loading={isSubmitting}
              >
                {isEdit ? 'Сохранить' : 'Создать'}
              </LoadingButton>

              {isEdit &&
                <LoadingButton
                  fullWidth
                  type="button"
                  variant="contained"
                  size="large"
                  color='error'
                  onClick={() => setOpenConfirm(true)}
                >
                  Удалить
                </LoadingButton>}

              <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Удалить акцию"
                content="Вы уверены что хотите удалить акцию?"
                action={
                  <Button variant="contained" color="error" onClick={handleDelete}>
                    Удалить
                  </Button>
                }
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
