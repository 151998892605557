import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'src/utils/axios';
import toFormData from 'src/utils/toFormData';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Card, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
// routes
// @types
import { ICategory } from '../../../@types/product';
// components
import { CustomFile } from '../../../components/upload';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFTextField, RHFUpload } from '../../../components/hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { createCategory } from './helpers/createCategory';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<ICategory, 'image' | 'id' | 'productParams'> {
	image: CustomFile | string;
	productParams: {
		name: string;
		id?: number;
		options: { name: string; id?: number }[];
	}[];
	paramsOrder: number[];
}

type Props = {
	isEdit?: boolean;
	currentProduct?: ICategory;
};

export default function CategoryNewEditForm({ isEdit, currentProduct }: Props) {
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const [showSpec, setShowSpec] = useState<{ [index: number]: boolean }>({});

	const NewProductSchema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		productParams: Yup.array().of(
			Yup.object().shape({
				name: Yup.string().required('Name is required'),
				options: Yup.array().of(
					Yup.object().shape({
						name: Yup.string().required('Name is required'),
					})
				),
			})
		),
	});

	const defaultValues = useMemo(
		() => {
			const sortedParams = currentProduct?.paramsOrder.map((paramId) => {
				const param = currentProduct?.productParams.find((item) => item.id === paramId);
				return param;
			});
			return {
				name: currentProduct?.name || '',
				image: currentProduct?.image || '',
				paramsOrder: currentProduct?.paramsOrder ?? [],
				productParams: sortedParams ?? [],
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentProduct]
	);

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(NewProductSchema),
		defaultValues,
	});

	const {
		reset,
		watch,
		setValue,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = methods;

	const values = watch();

	useEffect(() => {
		if (isEdit && currentProduct) {
			reset(defaultValues);
		}
		if (!isEdit) {
			reset(defaultValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEdit, currentProduct]);

	const onSubmit = async (data: FormValuesProps) => {
		try {
			if (isEdit && currentProduct) {
				const allData = createCategory(data, currentProduct);
				console.log(allData);

				await axios({
					method: 'patch',
					url: `/categories/${currentProduct?.id}`,
					data: toFormData({ ...allData }),
				});
			} else {
				await axios({
					method: 'post',
					url: '/categories',
					data: toFormData({ data }),
				});
			}

			reset();
			enqueueSnackbar(!isEdit ? 'Категория успешно создана!' : 'Категория успешно обновлена!');
			navigate(PATH_DASHBOARD.eCommerce.catList);
		} catch (error) {
			console.error(error);
		}
	};

	const handleDrop = useCallback(
		(acceptedFiles: File[]) => {
			const file = acceptedFiles[0];

			const newFile = Object.assign(file, {
				preview: URL.createObjectURL(file),
			});

			if (file) {
				setValue('image', newFile, { shouldValidate: true });
			}
		},
		[setValue]
	);

	const handleDeleteFile = () => {
		setValue('image', '');
	};

	const onDragEnd = (result: DropResult) => {
		const { destination, source, draggableId } = result;

		if (!destination) return;

		if (destination.index === source.index) return;

		if (values.paramsOrder) {
			const newParmsOrder = Array.from(values.paramsOrder);

			newParmsOrder.splice(source.index, 1);

			newParmsOrder.splice(destination.index, 0, +draggableId);

			const newParamsList = Array.from(values.productParams);

			newParamsList.splice(source.index, 1);

			newParamsList.splice(destination.index, 0, values.productParams[source.index]);

			setValue('paramsOrder', newParmsOrder);
			setValue('productParams', newParamsList);

			return;
		}
	};
	console.log(values.productParams);

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<Stack spacing={3}>
						<Card sx={{ p: 3 }}>
							<Stack spacing={3}>
								<RHFTextField name="name" label="Название категории" />

								<Stack spacing={1}>
									<Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
										Обложка
									</Typography>

									<RHFUpload
										thumbnail
										name="image"
										maxSize={3145728}
										onDrop={handleDrop}
										onDelete={handleDeleteFile}
										onUpload={() => console.log('ON UPLOAD')}
									/>
								</Stack>
							</Stack>
						</Card>

						<LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
							{!isEdit ? 'Создать' : 'Сохранить изменения'}
						</LoadingButton>
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack spacing={3}>
						<Card sx={{ px: 2, py: 3, background: 'none', boxShadow: 'none' }} variant="outlined">
							<Stack spacing={3}>
								<Stack direction="row" justifyContent="space-between" alignItems="center">
									<Typography variant="subtitle2" sx={{ color: 'text.secondary', px: 2 }}>
										Характеристики товаров категории
									</Typography>

									<Button
										aria-label="add-option"
										startIcon={<AddIcon />}
										onClick={() => {
											setValue('paramsOrder', [...values.paramsOrder, -1]);
											setValue('productParams', [...values.productParams, { name: '', options: [] }]);
										}}
									>
										Добавить
									</Button>
								</Stack>

								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="allParams" direction="vertical" type="column">
										{(provided) => (
											<Stack spacing={1} {...provided.droppableProps} ref={provided.innerRef}>
												{values.productParams.map((param, paramIndex) => {
													return (
														<Draggable
															draggableId={param.id?.toString() ?? `param-${paramIndex}`}
															index={paramIndex}
															key={'param-' + paramIndex}
														>
															{(provided) => (
																<Card
																	sx={{ p: 2 }}
																	key={'param-' + paramIndex}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	ref={provided.innerRef}
																>
																	<Stack spacing={3}>
																		<Stack
																			direction="row"
																			justifyContent="space-between"
																			alignItems="center"
																			spacing={2}
																		>
																			<TextField
																				value={param.name}
																				label="Характеристика товара"
																				multiline
																				fullWidth
																				onChange={(e) => {
																					const newData = values.productParams.slice().map((p, i) => {
																						if (i === paramIndex) return { ...p, name: e.target.value };
																						return p;
																					});
																					setValue('productParams', newData);
																				}}
																			/>

																			<IconButton
																				onClick={() =>
																					setShowSpec({ ...showSpec, [paramIndex]: !showSpec[paramIndex] })
																				}
																			>
																				{showSpec[paramIndex] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
																			</IconButton>

																			<IconButton
																				aria-label="add-option"
																				onClick={() => {
																					const arr = values.productParams.filter((_, i) => i !== paramIndex);

																					setValue('productParams', arr);
																				}}
																			>
																				<RemoveIcon />
																			</IconButton>
																		</Stack>

																		{showSpec[paramIndex] && (
																			<Stack spacing={2}>
																				<Stack direction="row" justifyContent="space-between" alignItems="center">
																					<Typography variant="subtitle2" sx={{ color: 'text.secondary', px: 2 }}>
																						Опции
																					</Typography>

																					<Button
																						aria-label="add-option"
																						startIcon={<AddIcon />}
																						onClick={() => {
																							const updatedParams = values.productParams;
																							updatedParams[paramIndex].options.push({ name: '' });
																							setValue('productParams', updatedParams);
																						}}
																					>
																						Добавить
																					</Button>
																				</Stack>

																				<Stack spacing={2}>
																					{param.options.length > 0 &&
																						param.options.map((option, optionIndex) => (
																							<Stack
																								direction="row"
																								justifyContent="space-between"
																								alignItems="center"
																								spacing={2}
																								key={`param-option-${optionIndex}`}
																							>
																								<TextField
																									value={option.name}
																									label="Опция"
																									multiline
																									fullWidth
																									onChange={(e) => {
																										const updatedParams = values.productParams;
																										updatedParams[paramIndex].options[optionIndex].name =
																											e.target.value;
																										setValue('productParams', updatedParams);
																									}}
																								/>

																								<IconButton
																									aria-label="remove-option"
																									onClick={() => {
																										const updatedParams = values.productParams;
																										updatedParams[paramIndex].options = param.options.filter(
																											(_, i) => i !== optionIndex
																										);
																										setValue('productParams', updatedParams);
																									}}
																								>
																									<RemoveIcon />
																								</IconButton>
																							</Stack>
																						))}
																				</Stack>
																			</Stack>
																		)}
																	</Stack>
																</Card>
															)}
														</Draggable>
													);
												})}
											</Stack>
										)}
									</Droppable>
								</DragDropContext>
							</Stack>
						</Card>
					</Stack>
				</Grid>
			</Grid>
		</FormProvider>
	);
}
