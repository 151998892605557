import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// sections
import { PromoNewEditForm } from 'src/sections/@dashboard/promotions';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function PromoCreatePage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Создание акции </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Создание акции"
          links={[
            {
              name: 'Главная',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Акции',
              href: PATH_DASHBOARD.banner.root,
            },
            {
              name: 'Создание',
            },
          ]}
        />

        <PromoNewEditForm />
      </Container>
    </>
  );
}
