import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ICategory } from "src/@types/product";
// @mui
import { Container } from "@mui/material";
// sections
import CategoryNewEditForm from "src/sections/@dashboard/e-commerce/CategoryNewEditForm";
// redux
import { getCategories } from "src/redux/slices/category";
import { useDispatch, useSelector } from "../../redux/store";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";

// ----------------------------------------------------------------------

export default function EcommerceCategoryEditPage() {
  const { themeStretch } = useSettingsContext();

  const dispatch = useDispatch();

  const { name } = useParams();

  const currentProduct = useSelector((state) =>
    state.category.categories.find((cat: ICategory) => cat.id.toString() === name)
  );

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title> Редактирование категории</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Редактирование категории"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            {
              name: 'Категории',
              href: PATH_DASHBOARD.eCommerce.catList,
            },
            { name: currentProduct?.name },
          ]}
        />

        <CategoryNewEditForm isEdit currentProduct={currentProduct} />
      </Container>
    </>
  );
}
