import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  // UserEditPage,
  // UserCardsPage,
  // UserCreatePage,
  // UserProfilePage,
  // UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,

  EcommerceCategoryListPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  // InvoiceCreatePage,
  // InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  NewsNewPage,
  NewsEditPage,
  // Dashboard: Banners
  BannersPage,
  BannerCreatePage,
  BannerEditPage,
  // Dashboard: Promotions
  PromotionsPage,
  PromoCreatePage,
  PromoEditPage,

  PortfolioListPage,
  PortfolioCreatePage,
  PortfolioEditPage,

  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  EcommerceCategoryCreatePage,
  EcommerceCategoryEditPage,
  //
  ReviewNewPage,
  ReviewsPage,
  ReviewEditPage,
  //
  ServicesPage,
  ServiceNewPage,
  ServiceEditPage,

  SettingsEditPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main
    {
      path: '/',
      element: <Navigate to='dashboard' replace />
    },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'ecommerce', element: <GeneralEcommercePage /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/product/list" replace />, index: true },
            { path: 'product/list', element: <EcommerceProductListPage /> },
            { path: 'product/new', element: <EcommerceProductCreatePage /> },
            { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
            { path: 'category/list', element: <EcommerceCategoryListPage /> },
            { path: 'category/new', element: <EcommerceCategoryCreatePage /> },
            { path: 'category/:name/edit', element: <EcommerceCategoryEditPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <UserListPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            // { path: ':id/edit', element: <InvoiceEditPage /> },
            // { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {path: 'portfolio',
          children: [
            { element: <Navigate to="/dashboard/portfolio/list" replace />, index: true },
            { path: 'list', element: <PortfolioListPage /> },
            { path: 'new', element: <PortfolioCreatePage /> },
            { path: ':name/edit', element: <PortfolioEditPage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'new', element: <NewsNewPage /> },
            { path: 'post/:id/edit', element: <NewsEditPage /> },
          ],
        },
        {
          path: 'promotions',
          children: [
            { element: <Navigate to="/dashboard/promotions/list" replace />, index: true },
            { path: 'list', element: <PromotionsPage /> },
            { path: 'new', element: <PromoCreatePage /> },
            { path: ':id/edit', element: <PromoEditPage /> },
          ],
        },
        {
          path: 'banner',
          children: [
            { element: <Navigate to="/dashboard/banner/list" replace />, index: true },
            { path: 'list', element: <BannersPage /> },
            { path: 'new', element: <BannerCreatePage /> },
            { path: ':id/edit', element: <BannerEditPage /> },
          ],
        },
        {
          path: 'settings',
          element: <SettingsEditPage />,
        },
        {
          path: 'reviews',
          children: [
            { element: <Navigate to="/dashboard/reviews/list" replace />, index: true },
            { path: 'list', element: <ReviewsPage /> },
            { path: 'new', element: <ReviewNewPage /> },
            { path: ':id/edit', element: <ReviewEditPage /> },
          ],
        },
        {
          path: 'services',
          children: [
            { element: <Navigate to="/dashboard/services/list" replace />, index: true },
            { path: 'list', element: <ServicesPage /> },
            { path: 'new', element: <ServiceNewPage /> },
            { path: ':id/edit', element: <ServiceEditPage /> },
          ],
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'pricing', element: <PricingPage /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
