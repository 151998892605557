import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Container} from "@mui/material";
import {useSettingsContext} from "../../../components/settings";
import axios from "../../../utils/axios";
import LoadingScreen from "../../../components/loading-screen";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {IPortfolio} from "../../../@types/portfolio";
import ProductNewEditForm from "../../../sections/@dashboard/portfolio/PortfolioNewEditForm";

const PortfolioEditPage = () => {
    const { themeStretch } = useSettingsContext();

    const { name } = useParams();

    const [service, setService] = useState<IPortfolio>()

    useEffect(() => {
        if (name)
            axios
                .get<any, {data:IPortfolio}>(`/portfolio/${name}`)
                .then((res) => setService(res.data))
                .catch((e) => console.log(e));
    }, [name]);

    if (!service) return <LoadingScreen />;
    return (
        <>
            <Helmet>
                <title>Редактирование</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                <CustomBreadcrumbs
                    heading="Редактирование карточки портфолио"
                    links={[
                        { name: 'Главная', href: PATH_DASHBOARD.root },
                        {
                            name: 'Портфолио',
                            href: PATH_DASHBOARD.portfolio.root,
                        },
                        { name: service?.title },
                    ]}
                />
                <ProductNewEditForm isEdit product={service} />
            </Container>
        </>
    );
};

export default PortfolioEditPage;