// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  portfolio:{
    root: path(ROOTS_DASHBOARD, '/portfolio'),
    new: path(ROOTS_DASHBOARD, '/portfolio/new'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/portfolio/${name}/edit`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/portfolio/${name}`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/product/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),

    catList: path(ROOTS_DASHBOARD, '/e-commerce/category/list'),
    catNew: path(ROOTS_DASHBOARD, '/e-commerce/category/new'),
    catEdit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/category/${name}/edit`),

    subcatList: path(ROOTS_DASHBOARD, '/e-commerce/subcategory/list'),
    subcatNew: path(ROOTS_DASHBOARD, '/e-commerce/subcategory/new'),
    subcatEdit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/subcategory/${name}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}/edit`),
  },
  reviews: {
    root: path(ROOTS_DASHBOARD, '/reviews'),
    list: path(ROOTS_DASHBOARD, '/reviews/list'),
    new: path(ROOTS_DASHBOARD, '/reviews/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/reviews/${id}/edit`),
  },
  services: {
    root: path(ROOTS_DASHBOARD, '/services'),
    list: path(ROOTS_DASHBOARD, '/services/list'),
    new: path(ROOTS_DASHBOARD, '/services/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/services/${id}/edit`),
  },
  banner: {
    root: path(ROOTS_DASHBOARD, '/banner'),
    list: path(ROOTS_DASHBOARD, '/banner/list'),
    new: path(ROOTS_DASHBOARD, '/banner/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/banner/${id}/edit`),
  },
  promotions: {
    root: path(ROOTS_DASHBOARD, '/promotions'),
    list: path(ROOTS_DASHBOARD, '/promotions/list'),
    new: path(ROOTS_DASHBOARD, '/promotions/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/promotions/${id}/edit`),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
