const toFormData = (data: { [i: string]: unknown }): FormData => {

    const formDataWithFiles = new FormData()
    let jsonData = {}

    // eslint-disable-next-line
    for (const key in data) {

        if (data[key] instanceof File) {
            formDataWithFiles.append(key, data[key] as Blob)

        } else if (data[key] instanceof Array) {
            const arr = data[key] as Array<unknown>

            const hasFile = arr.find(el => el instanceof File)
            if (hasFile) arr.forEach((file) => formDataWithFiles.append(key, file as Blob))
            else jsonData = { ...jsonData, [key]: data[key] }

        } else {
            jsonData = { ...jsonData, [key]: data[key] }
        }
    }

    const formData = new FormData()
    formData.append('serialized-json', JSON.stringify(jsonData))
    formDataWithFiles.forEach((value, key) => formData.append(key, value))

    return formData
}

export default toFormData