import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// sections
import BannerNewEditForm from 'src/sections/@dashboard/blog/BannerNewEditForm';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function BannerCreatePage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Добавить баннер </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Добавить баннер"
          links={[
            {
              name: 'Главная',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Баннеры',
              href: PATH_DASHBOARD.banner.root,
            },
            {
              name: 'Добавить',
            },
          ]}
        />

        <BannerNewEditForm />
      </Container>
    </>
  );
}
