import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'src/utils/axios';
import toFormData from 'src/utils/toFormData';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Stack, Button, Typography, InputAdornment, Box } from '@mui/material';
import ConfirmDialog from 'src/components/confirm-dialog';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { IService } from 'src/@types/product';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
    RHFSwitch,
    RHFEditor,
    RHFUpload,
    RHFTextField,
    RHFAutocomplete,
} from '../../../components/hook-form';


// ----------------------------------------------------------------------

type Props = {
    isEdit?: boolean;
    current?: IService;
};


export default function ServiceNewEditForm({ isEdit, current }: Props) {
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [openConfirm, setOpenConfirm] = useState(false)

    const NewBlogSchema = Yup.object().shape({
        name: Yup.string().required('Укажите название'),
        price: Yup.number().required('Не заполнен текст новости'),
    });

    const defaultValues = useMemo(
        () => ({
            name: current?.name || '',
            price: current?.price || 0,
            description: current?.description || '',
            priceInSet: current?.priceInSet || 0,
        }), [current]);

    const methods = useForm<IService>({
        resolver: yupResolver(NewBlogSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid },
    } = methods;

    const onSubmit = async (data: IService) => {
        console.log(data)
        try {
            await axios({
                method: isEdit ? 'patch' : 'post',
                url: isEdit && current
                    ? `/services/${current.id}`
                    : `/services`,
                data: toFormData({ ...data })
            })

            reset();
            enqueueSnackbar('Успешно!');
            navigate(PATH_DASHBOARD.services.list);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }

    const handleDelete = async () => {
        if (!current) return

        await axios({
            method: 'delete',
            url: `/services/${current.id}`
        })
        enqueueSnackbar('Услуга удалена');
        navigate(PATH_DASHBOARD.services.list);
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>
                            <RHFTextField name="name" label="Название услуги" />

                            <Stack spacing={1}>
                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Описание
                                </Typography>

                                <RHFEditor simple name="description" />
                            </Stack>
                        </Stack>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card sx={{ p: 3 }}>
                        <Stack spacing={3}>


                            <RHFTextField
                                name="price"
                                label="Цена"
                                placeholder="0.00"
                                onChange={(event) =>
                                    setValue('price', Number(event.target.value), { shouldValidate: true })
                                }
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Box component="span" sx={{ color: 'text.disabled' }}>
                                                ₽
                                            </Box>
                                        </InputAdornment>
                                    ),
                                    type: 'number',
                                }}
                            />

                            <RHFTextField
                                name="priceInSet"
                                label="Цена при покупке с товаром"
                                placeholder="0.00"
                                onChange={(event) =>
                                    setValue('priceInSet', Number(event.target.value), { shouldValidate: true })
                                }
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Box component="span" sx={{ color: 'text.disabled' }}>
                                                ₽
                                            </Box>
                                        </InputAdornment>
                                    ),
                                    type: 'number',
                                }}
                            />

                        </Stack>
                    </Card>

                    <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>

                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            loading={isSubmitting}
                        >
                            {isEdit ? 'Сохранить' : 'Создать'}
                        </LoadingButton>

                        {isEdit &&
                            <LoadingButton
                                fullWidth
                                type="button"
                                variant="contained"
                                size="large"
                                color='error'
                                onClick={() => setOpenConfirm(true)}
                            >
                                Удалить
                            </LoadingButton>}

                        <ConfirmDialog
                            open={openConfirm}
                            onClose={handleCloseConfirm}
                            title="Удалить новость"
                            content="Вы уверены что хотите удалить новость?"
                            action={
                                <Button variant="contained" color="error" onClick={handleDelete}>
                                    Удалить
                                </Button>
                            }
                        />
                    </Stack>
                </Grid>
            </Grid>
        </FormProvider>
    );
}
