import orderBy from 'lodash/orderBy';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';
// @mui
import { Grid, Button, Container, Stack } from '@mui/material';
// sections
import BannerCard from 'src/sections/@dashboard/blog/BannerCard';
// utils
import axios from '../../utils/axios';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// @types
import { IBlogPost } from '../../@types/blog';
// components
import Iconify from '../../components/iconify';
import { SkeletonPostItem } from '../../components/skeleton';
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Последние' },
  { value: 'popular', label: 'Популярные' },
  { value: 'oldest', label: 'Старые' },
];

// ----------------------------------------------------------------------

export default function BannersPage() {
  const { themeStretch } = useSettingsContext();

  const [banners, setBanners] = useState([])

  const [sortBy, setSortBy] = useState('latest');

  const sortedPosts = applySortBy(banners, sortBy);

  const getAllPosts = useCallback(async () => {
    try {
      const response = await axios.get('/banners');
      setBanners(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getAllPosts();
  }, [getAllPosts]);

  const handleChangeSortBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> Баннеры </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Баннеры"
          links={[
            {
              name: 'Главная',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Баннеры',
              href: PATH_DASHBOARD.blog.root,
            },
            {
              name: 'Все',
            },
          ]}
          action={
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.banner.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Добавить
            </Button>
          }
        />

        <Grid container spacing={3}>
          {(!banners.length ? [...Array(12)] : sortedPosts).map((post, index) =>
            post ? (
              <Grid key={post.id} item xs={12} sm={6} md={6}>
                <BannerCard post={post} index={index} />
              </Grid>
            ) : (
              <SkeletonPostItem key={index} />
            )
          )}
        </Grid>
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

const applySortBy = (posts: IBlogPost[], sortBy: string) => {
  if (sortBy === 'latest') {
    return orderBy(posts, ['createdAt'], ['desc']);
  }

  if (sortBy === 'oldest') {
    return orderBy(posts, ['createdAt'], ['asc']);
  }

  if (sortBy === 'popular') {
    return orderBy(posts, ['view'], ['desc']);
  }
  return posts;
};
