import { ICategory } from '../../../../@types/product';
import { CustomFile } from '../../../../components/upload';

type IO = {
	toDelete: Array<any>;
	toCreate: Array<any>;
	toUpdate: Array<any>;
};

interface FormValuesProps extends Omit<ICategory, 'image' | 'id' | 'productParams'> {
	image: CustomFile | string;
	productParams: {
		name: string;
		id?: number;
		options: { name: string; id?: number }[];
	}[];
}

export const createCategory = (data: FormValuesProps, currentProduct: ICategory) => {
	const { name, image, productParams, paramsOrder } = data;
	const { id } = currentProduct;
	const productParamsObject: IO = {
		toDelete: [],
		toCreate: [],
		toUpdate: [],
	};

	const productParamsOptions: IO = {
		toDelete: [],
		toCreate: [],
		toUpdate: [],
	};

	productParams?.forEach((paramFromNewState) => {
		const { name, id, options } = paramFromNewState;
		const productParam = currentProduct?.productParams?.find((el) => el.id === id);
		if (!productParam) {
			productParamsObject.toCreate.push({ name, options });
		} else {
			if (productParam.name !== name) productParamsObject.toUpdate.push({ name, id });

			options.forEach((option) => {
				const index = productParam.options.findIndex((item) => item.id === option.id);
				if (index < 0) {
					productParamsOptions.toCreate.push({ ...option, productParamsId: id });
				}
			});

			productParam.options.forEach((option) => {
				const index = options.findIndex((item) => item.id === option.id);
				if (index < 0) productParamsOptions.toDelete.push(option);
				else {
					if (option.name !== options[index].name) {
						productParamsOptions.toUpdate.push(options[index]);
					}
				}
			});
		}
	});

	currentProduct?.productParams?.forEach((element) => {
		const { name, id } = element;
		const productParam = productParams?.find((el) => el.id == id);
		if (!productParam) productParamsObject.toDelete.push({ name, id });
	});

	const newParamsOrder = paramsOrder.filter((item) => item !== -1);

	return {
		id,
		name,
		image,
		paramsOrder: newParamsOrder,
		productParams: productParamsObject,
		paramsOptions: productParamsOptions,
	};
};
