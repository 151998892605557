import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { HOST_API_KEY } from 'src/config-global';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Avatar, Typography, CardContent, Stack, Link } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
// @types
import { IBlogPost, IReview } from '../../../@types/blog';
// components
import Image from '../../../components/image';
import Iconify from '../../../components/iconify';
import TextMaxLine from '../../../components/text-max-line';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

// ----------------------------------------------------------------------

type Props = {
  post: IReview;
  index?: number;
};

export default function ReviewCard({ post, index }: Props) {
  const isDesktop = useResponsive('up', 'md');

  const { id, rate, review } = post;

  return (
    <Card variant='outlined'>
      <PostContent
        id={id}
        rate={rate}
        review={review}
      />

      <StyledOverlay />

      <Image alt="cover" src={''} ratio="4/3" />
    </Card>
  );

}

// ----------------------------------------------------------------------

export function PostContent({ id, rate, review }: IReview) {
  const isDesktop = useResponsive('up', 'md');

  const linkTo = PATH_DASHBOARD.reviews.edit(id.toString());

  return (
    <CardContent
      sx={{
        width: 1,
        pt: 0,
        zIndex: 9,
        bottom: 0,
        position: 'absolute',
        color: 'common.white',
      }}
    >
      <Typography
        gutterBottom
        variant="caption"
        component="div"
        sx={{
          opacity: 0.64,
          color: 'common.white',
        }}
      >
        <StarRoundedIcon fontSize='small' />{rate}
      </Typography>

      <Link color="inherit" component={RouterLink} to={linkTo}>
        <TextMaxLine
          variant={isDesktop ? 'h5' : 'subtitle2'}
          line={2}
          persistent
        >
          {review}
        </TextMaxLine>
      </Link>

    </CardContent>
  );
}
