import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
// sections
import CategoryNewEditForm from '../../sections/@dashboard/e-commerce/CategoryNewEditForm';

// ----------------------------------------------------------------------

export default function EcommerceCategoryCreatePage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Создание категории</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Создание категории"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            {
              name: 'Категории',
              href: PATH_DASHBOARD.eCommerce.catList,
            },
            { name: 'Создание' },
          ]}
        />
        <CategoryNewEditForm />
      </Container>
    </>
  );
}
